body {
  margin: 0;
  font-family: Prompt, Muli, Helvetica Neue, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: Prompt, Muli, Helvetica Neue, Arial, sans-serif;
}
